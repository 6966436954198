// Aligned with backend configuration values
const QC_NUM_PRICE = 795;
const QC_NAME_PRICE = 850;
const CA_NUM_PRICE = 995;
const CA_NAME_PRICE = 1095;

const PACKAGES = [
  {
    product: 'QC_NUM',
    region: 'quebec',
    price: QC_NUM_PRICE
  },
  {
    product: 'QC_NAME',
    region: 'quebec',
    price: QC_NAME_PRICE
  },
  {
    product: 'CA_NUM',
    region: 'canada',
    price: CA_NUM_PRICE
  },
  {
    product: 'CA_NAME',
    region: 'canada',
    price: CA_NAME_PRICE
  }
];

const QC_PRICE_DIFF = QC_NAME_PRICE - QC_NUM_PRICE;
const CA_PRICE_DIFF = CA_NAME_PRICE - CA_NUM_PRICE;

const FUNCTIONALITY_PERMISSION = {
  PROFESSIONALACCESS: "read-write:professionalaccess",
  REQ: "read-write:professionalaccess-req",
  INCORPORATION: "read-write:professionalaccess-incorporation"
};

export {
  PACKAGES,
  QC_PRICE_DIFF,
  CA_PRICE_DIFF,
  FUNCTIONALITY_PERMISSION
};